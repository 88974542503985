import { createContext, useState } from "react";

export const OpeningSoonContext = createContext();

export const OpeningSoonProvider = ({ children }) => {
  const [warning, setWarning] = useState(false);


  return (
    <OpeningSoonContext.Provider value={{ warning, setWarning }}>
      {children}
    </OpeningSoonContext.Provider>
  );
};