import { createSlice } from '@reduxjs/toolkit'

const initialState = { message: false, status: "none", title: null }

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    changeMessages(state, action) {
      state.message = action.payload.message
      state.status = action.payload.status
      state.title = action.payload.title
    },
  },
})

export const { changeMessages } = snackbarSlice.actions
export default snackbarSlice.reducer